import { DescriptionTwoTone } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyState from '../../components/EmptyState';
import { MapPoint } from '../../components/MapView';
import SectionBody from '../../components/SectionBody';
import { useLazySearchDocumentsQuery } from '../../state/api';
import {
  finishDocumentUpload,
  selectDocumentsInProgresNotCreated,
  selectDocumentsInProgress,
  selectDocumentsInProgressById,
  updateDocumentUpload
} from '../../state/documentProgressSlice';
import { DocumentDetails, DocumentSearchQuery, SortOrder } from '../../types/document';
import DocumentsKpiView from './DocumentsKpiView';
import DocumentsOverview from './DocumentsOverview';
import MapComponent from './map';
import { ContractingPartyMarker, convert } from './map/model';
import UploadArea from './UploadArea';

export interface DocumentNotCreated {
  id: string;
  name: string;
  uploadStarted: string;
}

export interface DocumentInProgress {
  id: string;
  progress: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ display: value === index ? 'block' : 'none' }}>{children}</Box>
    </div>
  );
}

export default function Documents() {
  const MAX_PROGRESS = 97;
  const dispatch = useDispatch();
  const documentsInProgress = useSelector(selectDocumentsInProgress);
  const documentsInProgressById = useSelector(selectDocumentsInProgressById);
  const documentsNotCreated = useSelector(selectDocumentsInProgresNotCreated);

  const [value, setValue] = useState(0);
  const [search, setSearch] = useState(localStorage.getItem('searchFilter') || '');
  const [searchLoading, setSearchLoading] = useState(false);
  const [sortedBy] = useState<string>('createdAt');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [contractsOnly, setContractsOnly] = useState<boolean>(true);

  const [documents, setDocuments] = useState<DocumentDetails[]>([]);
  // To avoid duplicate document refresh requests
  const [searchRequestId, setSearchRequestId] = useState<string>('');

  const [searchDocuments, result] = useLazySearchDocumentsQuery();
  const [loading, setLoading] = useState<boolean>(result.isUninitialized);

  const handleChange = (_: React.ChangeEvent<object>, newValue: number) => {
    setValue(newValue);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchLoading(true);
    localStorage.setItem('searchFilter', event.target.value);
    setSearch(event.target.value);
  };
  const handleChangeSortOrder = () => {
    setSortOrder((prev) => {
      if (prev === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
  };

  // Fake increasing progress for documents in progress
  useEffect(() => {
    const progressId = setInterval(() => {
      documentsInProgress.forEach((documentInProgress) => {
        if (documentInProgress.progress < MAX_PROGRESS) {
          dispatch(
            updateDocumentUpload({ id: documentInProgress.id, progress: documentInProgress.progress + Math.random() })
          );
        }
      });
      if (documentsInProgress.length === 0 || documentsInProgress.every((d) => d.progress > MAX_PROGRESS)) {
        clearInterval(progressId);
      }
    }, 1000);
    return () => clearInterval(progressId);
  }, [documentsInProgress]);

  // Replace document not created with document in progress
  useEffect(() => {
    if (documentsNotCreated.length > 0) {
      const existingNames = documents.map((d) => d.name);
      const notCreatedDupe = documentsNotCreated.find((d) => existingNames.includes(d.name));
      if (notCreatedDupe) {
        dispatch(finishDocumentUpload({ id: notCreatedDupe.id }));
      }
    }
  }, [documentsNotCreated, documents]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const q: DocumentSearchQuery = {
        query: search,
        onlyContracts: contractsOnly,
        deleted: false,
        sortedBy: 'createdAt',
        sortOrder: sortOrder
      };
      await searchDocuments(q);
      setSearchLoading(false);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    const q: DocumentSearchQuery = {
      query: search,
      onlyContracts: contractsOnly,
      deleted: false,
      sortedBy: 'createdAt',
      sortOrder: sortOrder
    };

    searchDocuments(q);
  }, [contractsOnly, sortOrder]);

  useEffect(() => {
    const data = Array.from(result.data ?? []);
    const isLoading = result.isLoading || result.isUninitialized;
    setLoading(isLoading);
    setDocuments(data);

    if (!isLoading && data.length && result.requestId !== searchRequestId) {
      setSearchRequestId(result.requestId);
    }
  }, [result]);

  const handleChangeInContractsOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContractsOnly(event.target.checked);
  };

  const mapPoints: MapPoint<ContractingPartyMarker>[] = useMemo(() => {
    if (documents.length > 0) {
      const partyPoints = documents.map((doc) => {
        return convert(doc)
          .filter((partyMarker) => partyMarker.location)
          .map((partyMarker) => {
            return {
              id: partyMarker.extractionId,
              lat: partyMarker.location.lat,
              lng: partyMarker.location.lng,
              data: partyMarker
            };
          });
      });
      return partyPoints.flat();
    } else {
      return [];
    }
  }, [documents]);

  return (
    <>
      <SectionBody>
        <TextField
          autoComplete="off"
          placeholder="Search in documents"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          size="medium"
          type="search"
          InputProps={{
            sx: { borderRadius: '3rem', backgroundColor: 'background.paper' },
            startAdornment: (
              <InputAdornment position="start">
                {searchLoading ? <CircularProgress size={24} /> : <SearchIcon />}
              </InputAdornment>
            )
          }}
          sx={{
            alignSelf: 'center',
            width: '100%',
            maxWidth: '42rem'
          }}
        />
        <FormGroup sx={{ alignSelf: 'center' }}>
          <FormControlLabel
            control={<Switch checked={contractsOnly} onChange={handleChangeInContractsOnly} />}
            label="Contracts only"
          />
        </FormGroup>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Overview" />
            <Tab label="Map View" />
            <Tab label="KPI View" />
          </Tabs>
          <UploadArea onProcessStarted={() => {}} />
        </Box>
        <TabPanel value={value} index={0}>
          {documents.length === 0 && documentsNotCreated.length === 0 && !loading ? (
            <EmptyState title="No documents found" icon={<DescriptionTwoTone />}>
              Upload a document to get started.
            </EmptyState>
          ) : (
            <DocumentsOverview
              documents={documents}
              documentsInProgressById={documentsInProgressById}
              documentsNotCreated={documentsNotCreated}
              isLoading={loading}
              sortedBy={sortedBy}
              sortOrder={sortOrder}
              onChangeSortOrder={handleChangeSortOrder}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MapComponent mapPoints={mapPoints} isLoading={loading} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DocumentsKpiView documents={documents} isLoading={loading} />
        </TabPanel>
      </SectionBody>
    </>
  );
}
