import { IconButton } from '@mui/material';
import React from 'react';
// import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
// import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlineBlank';
import Tooltip from '@mui/material/Tooltip';
import { DocumentKpiStatus } from '../types/document';

interface StatusDotProps {
  status?: DocumentKpiStatus;
  tooltip?: React.ReactNode;
  onClick?: () => void;
}

const StatusDot = ({ status, tooltip, onClick }: StatusDotProps) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const getIcon = (status: DocumentKpiStatus) => {
    if (status === 'NOT_MAPPED') {
      return <CheckBoxOutlined />;
    }
    return <SquareIcon />;
  };

  const getColor = (status: DocumentKpiStatus) => {
    switch (status) {
      case 'RED':
        return 'error';
      case 'AMBER':
        return 'warning';
      case 'GREEN':
        return 'success';
      case 'NOT_MAPPED':
        return 'default';
      default:
        return 'inherit';
    }
  };

  const color = getColor(status);
  const icon = getIcon(status);

  const button = (
    <IconButton size="small" color={color} onClick={handleOnClick}>
      {icon}
    </IconButton>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow placement="top">
        {button}
      </Tooltip>
    );
  }

  return button;
};

export default StatusDot;
