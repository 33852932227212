import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  isIdEvent,
  isTaskEvent,
  WebSocketChatEvent,
  WebSocketDocumentEvent,
  WebSocketIdEvent,
  WebSocketTaskEvent
} from '../state/model';
import { v4 as uuidv4 } from 'uuid';
import { setClientId } from '../state/clientSlice';
import { api } from '../state/api';
import { config } from '../config';
import { useAuth } from '../hooks/useAuth';
import { finishDocumentUpload, updateDocumentUpload } from '../state/documentProgressSlice';

const SocketContext = createContext<WebSocket | null>(null);

interface Message {
  type:
    | 'ANNOTATION_CREATED'
    | 'WEBSOCKET_CONNECTED'
    | 'DOCUMENT_CREATED'
    | 'TEXT_CREATED'
    | 'TEXT_ANNOTATION_CREATED'
    | 'DOCUMENT_SET_UPDATED'
    | 'CHAT_UPDATED'
    | 'CUP_UPDATED'
    | 'TASK_UPDATED'
    | 'TASK_LOGIC_EXPRESSION_UPDATED'
    | 'FLOW_CREATED'
    | 'FLOW_UPDATED'
    | 'MIRROR_UPDATED'
    | 'PROCESS_UPDATED'
    | 'MIRROR_WORKFLOW_UPDATED';
  requestId: string;
  data: WebSocketIdEvent | WebSocketDocumentEvent | WebSocketChatEvent;
}

interface SocketProviderProps {
  children: React.ReactNode;
}

type SocketValueType = WebSocket & {
  sendMessage: (message: string) => void;
};

export function SocketProvider({ children }: SocketProviderProps) {
  const { user, isLoggedIn } = useAuth();
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();

  const getWsApiUrl = () => {
    let protocol = 'ws:';
    if (location.protocol == 'https:') {
      protocol = 'wss:';
    }

    if (config.apiUrl.length > 0) {
      const url = new URL(config.apiUrl);
      if (url.protocol == 'https:') {
        url.protocol = 'wss:';
      } else {
        url.protocol = 'ws:';
      }
      url.pathname = 'api/events';
      return url.href;
    }

    return `${protocol}//${location.host}/api/events`;
  };

  const invalidateDocumentTags = (id: string, list = true) => {
    const tags = [{ type: 'Document' as const, id }];
    if (list) {
      tags.push({ type: 'Document' as const, id: 'LIST' });
    }
    dispatch(api.util.invalidateTags(tags));
  };

  useEffect(() => {
    if (isLoggedIn && user.activated) {
      const setupSocket = () => {
        const ws = new WebSocket(getWsApiUrl());

        ws.onopen = () => {
          const authRequest: WebSocketIdEvent = {
            id: uuidv4()
          };

          ws.send(JSON.stringify(authRequest));
        };
        ws.onmessage = (event: MessageEvent) => {
          const jsonObj = JSON.parse(event.data);
          const message: Message = jsonObj as Message;

          switch (message.type) {
            case 'DOCUMENT_CREATED':
              if (isIdEvent(message.data)) {
                dispatch(updateDocumentUpload({ id: message.data.id, progress: 5 }));
                invalidateDocumentTags(message.data.id);
              }
              break;
            case 'TEXT_CREATED':
              if (isIdEvent(message.data)) {
                dispatch(updateDocumentUpload({ id: message.data.id, progress: 20 }));
                invalidateDocumentTags(message.data.id, false);
              }
              break;
            case 'TEXT_ANNOTATION_CREATED':
              if (isIdEvent(message.data)) {
                dispatch(updateDocumentUpload({ id: message.data.id, progress: 40 }));
                invalidateDocumentTags(message.data.id, false);
              }
              break;
            case 'ANNOTATION_CREATED':
              if (isIdEvent(message.data)) {
                dispatch(updateDocumentUpload({ id: message.data.id, progress: 99 }));
                setTimeout(() => {
                  dispatch(finishDocumentUpload({ id: isIdEvent(message.data) ? message.data.id : '' }));
                }, 500);
                invalidateDocumentTags(message.data.id);
              } else {
                console.warn('Invalid document created event');
              }

              break;
            case 'DOCUMENT_SET_UPDATED':
              {
                if (isIdEvent(message.data)) {
                  dispatch(api.util.invalidateTags([{ type: 'DocumentSet', id: message.data.id }]));
                } else {
                  console.warn('Invalid document set update event');
                }
              }
              break;
            case 'CHAT_UPDATED':
              {
                if (isIdEvent(message.data)) {
                  const data: WebSocketIdEvent = message.data;
                  dispatch(api.util.invalidateTags([{ type: 'Chat', id: data.id }]));
                  dispatch(api.util.invalidateTags([{ type: 'Chat', id: 'LIST' }]));
                } else {
                  console.warn('Invalid chat update event');
                }
              }
              break;
            case 'CUP_UPDATED':
              {
                if (isIdEvent(message.data)) {
                  dispatch(api.util.invalidateTags([{ type: 'Cup', id: message.data.id }]));
                  dispatch(api.util.invalidateTags([{ type: 'Cup', id: 'LIST' }]));
                } else {
                  console.warn('Invalid cup update event');
                }
              }
              break;

            case 'FLOW_CREATED':
            case 'FLOW_UPDATED':
              {
                if (isIdEvent(message.data)) {
                  dispatch(
                    api.util.invalidateTags([
                      { type: 'WorkflowBasic', id: 'LIST' },
                      { type: 'WorkflowBasic', id: message.data.id },
                      { type: 'WorkflowInstance', id: message.data.id }
                    ])
                  );
                } else {
                  console.warn('Invalid flow event');
                }
              }
              break;

            case 'MIRROR_UPDATED':
              {
                if (isIdEvent(message.data)) {
                  dispatch(
                    api.util.invalidateTags([
                      { type: 'Mirror', id: message.data.id },
                      { type: 'MirrorBasic', id: message.data.id },
                      { type: 'MirrorBasic', id: 'LIST' }
                    ])
                  );
                } else {
                  console.warn('Invalid mirror event');
                }
              }
              break;
            case 'PROCESS_UPDATED':
              {
                if (isIdEvent(message.data)) {
                  dispatch(api.util.invalidateTags([{ type: 'MirrorState', id: message.data.id }]));
                } else {
                  console.warn('Invalid process update event');
                }
              }
              break;
            case 'MIRROR_WORKFLOW_UPDATED':
              {
                if (isIdEvent(message.data)) {
                  dispatch(api.util.invalidateTags([{ type: 'WorkflowDefinition', id: message.data.id }]));
                } else {
                  console.warn('Invalid mirror workflow event');
                }
              }
              break;
            case 'TASK_LOGIC_EXPRESSION_UPDATED':
              {
                if (isIdEvent(message.data)) {
                  dispatch(api.util.invalidateTags([{ type: 'TaskLogicExpression', id: message.data.id }]));
                } else {
                  console.warn('Invalid task update event');
                }
              }
              break;
            case 'TASK_UPDATED':
              {
                if (isTaskEvent(message.data)) {
                  const data: WebSocketTaskEvent = message.data;
                  dispatch(
                    api.util.invalidateTags([
                      { type: 'Task', id: data.taskId },
                      { type: 'TaskBasic', id: data.taskId },
                      { type: 'WorkflowInstance', id: data.workflowInstanceId }
                    ])
                  );
                } else {
                  console.warn('Invalid task update event');
                }
              }
              break;
            case 'WEBSOCKET_CONNECTED':
              {
                if (isIdEvent(message.data)) {
                  const data: WebSocketIdEvent = message.data;
                  dispatch(setClientId(data.id));
                } else {
                  console.warn('Invalid websocket init event');
                }
              }
              break;
          }
        };

        ws.onclose = () => {
          setSocket(null);

          setTimeout(() => {
            setupSocket();
          }, 1000);
        };
        setSocket(ws);
      };
      setupSocket();
    }
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [user, isLoggedIn]);

  const sendMessage = (message: string) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    }
  };

  const value: SocketValueType = {
    ...socket,
    sendMessage
  };

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
}
