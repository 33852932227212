import React from 'react';
import MapView, { MapPoint } from '../../../components/MapView';
import { Box } from '@mui/material';

interface MapProps {
  mapPoints: MapPoint<unknown>[];
  isLoading: boolean;
}

const Map = ({ mapPoints, isLoading }: MapProps) => {
  return (
    <Box>
      <MapView points={mapPoints} isLoading={isLoading} />
    </Box>
  );
};

export default Map;
