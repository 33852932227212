import { PlayArrow } from '@mui/icons-material';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Button, Stack, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppBreadcrumbs, { BreadcrumbItem } from '../../components/AppBreadcrumbs';
import ProgressCircle from '../../components/ProgressCircle';
import SectionBody from '../../components/SectionBody';
import WorkflowGraph, { FlowGraphEvent } from '../../components/workflow/WorkflowGraph';
import {
  useGetDocumentSetByIdQuery,
  useGetWorkflowDefinitionByIdQuery,
  useGetWorkflowInstanceByIdQuery,
  useResetWorkflowInstanceByIdMutation,
  useRunWorkflowMutation
} from '../../state/api';
import { TaskStatus } from '../../types/task';
import { UploadReferenceType } from '../../types/upload';
import { TaskAndProgress, TaskProgress } from '../../types/workflow';
import UploadArea from '../documents/UploadArea';
import { DocumentSetView } from './DocumentSetView';
import { WorkflowProgress } from './WorkflowProgress';

const emptyProgress: TaskProgress = {
  taskId: '',
  status: TaskStatus.NOT_STARTED,
  completed: 0,
  inProgress: 0,
  done: 0,
  skipped: 0,
  failed: 0
};

export const WorkflowView = () => {
  const { workflowInstanceId } = useParams();
  const [workflowId, setWorkflowId] = useState<string>('');
  const [documentSetId, setDocumentSetId] = useState<string>('');
  const { data: workflow, isLoading } = useGetWorkflowInstanceByIdQuery(workflowInstanceId);
  const { data: definition } = useGetWorkflowDefinitionByIdQuery(workflowId, {
    skip: workflowId.length === 0
  });
  const { data: documentSet } = useGetDocumentSetByIdQuery(documentSetId, {
    skip: documentSetId.length === 0
  });
  const [runWorkflow] = useRunWorkflowMutation();
  const [resetWorkflow] = useResetWorkflowInstanceByIdMutation();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);

  const isTaskSelected = (taskId: string) => {
    return selectedTasks.includes(taskId);
  };

  const handleTaskClick = (taskId: string) => {
    if (isTaskSelected(taskId)) {
      setSelectedTasks((prev) => prev.filter((t) => t !== taskId));
    } else {
      setSelectedTasks((prev) => [...prev, taskId]);
    }
  };

  useEffect(() => {
    if (workflow) {
      setWorkflowId(workflow.workflowId);
      setDocumentSetId(workflow.documentSetId);
    }
  }, [workflow]);

  const noFilesUploaded: boolean = useMemo(() => {
    if (documentSet) {
      return documentSet.documents.length === 0;
    }
    return true;
  }, [documentSet]);

  useEffect(() => {
    if (!noFilesUploaded) {
      setActiveStep(1);
    }
  }, [noFilesUploaded]);

  const tasks: TaskAndProgress[] = useMemo(() => {
    if (definition && workflow) {
      const progressByTaskId = new Map();
      workflow.progress.forEach((p) => progressByTaskId.set(p.taskId, p));

      return definition.tasks.map((d) => {
        const progress = progressByTaskId.get(d.taskId) || { ...emptyProgress, taskId: d.taskId };
        return { ...d, ...progress };
      });
    } else {
      return [];
    }
  }, [definition, workflow]);

  if (isLoading) {
    return <ProgressCircle />;
  }

  const handleRunWorkflow = async () => {
    try {
      await runWorkflow(workflowInstanceId).unwrap();
    } catch (error) {
      console.error('Failed to run workflow:', error);
    }
  };

  const handleResetWorkflow = async () => {
    try {
      await resetWorkflow(workflowInstanceId).unwrap();
    } catch (error) {
      console.error('Failed to reset workflow', error);
    }
  };

  const handleGraphEvent = (event: FlowGraphEvent) => {
    handleTaskClick(event.nodeId);
  };

  const breadcrumbs: BreadcrumbItem[] = [
    {
      label: 'Workflows',
      to: '/workflows'
    },
    {
      label: workflow?.name
    }
  ];

  return (
    <>
      <Stack spacing={2}>
        <AppBreadcrumbs items={breadcrumbs} />
      </Stack>

      <SectionBody overflow="auto" fillHeight>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              flex: 1
            }}
          >
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step active={activeStep >= 0}>
                <StepLabel>Upload Files</StepLabel>
                <StepContent>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1
                    }}
                  >
                    <UploadArea
                      reference={{
                        referenceId: workflowInstanceId,
                        referenceType: UploadReferenceType.WORKFLOW,
                        documentSetId: workflow.documentSetId
                      }}
                      onProcessStarted={() => {}}
                      disabled={workflow.status !== TaskStatus.NOT_STARTED}
                    />
                    <DocumentSetView documentSet={documentSet} />
                  </Box>
                </StepContent>
              </Step>

              <Step>
                <StepLabel>Run Workflow</StepLabel>
                <StepContent>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PlayArrow />}
                        size="large"
                        onClick={handleRunWorkflow}
                        disabled={noFilesUploaded || workflow.status !== TaskStatus.NOT_STARTED}
                      >
                        Run
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        startIcon={<LockResetIcon />}
                        size="large"
                        onClick={handleResetWorkflow}
                        disabled={noFilesUploaded || workflow.status === TaskStatus.NOT_STARTED}
                      >
                        Reset
                      </Button>
                    </Box>
                    <WorkflowProgress
                      workflow={workflow}
                      tasks={tasks}
                      isTaskSelected={isTaskSelected}
                      onTaskClick={handleTaskClick}
                    />
                  </Box>
                </StepContent>
              </Step>
            </Stepper>
          </Box>

          <Box
            sx={{
              flex: 1
            }}
          >
            <WorkflowGraph tasks={tasks} onEvent={handleGraphEvent} />
          </Box>
        </Box>
      </SectionBody>
    </>
  );
};
