import { DescriptionTwoTone } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import AppBreadcrumbs, { BreadcrumbItem } from '../../components/AppBreadcrumbs';
import EmptyState from '../../components/EmptyState';
import SectionBody from '../../components/SectionBody';
import {
  useCreateCpmWorkflowMutation,
  useGetDocumentByIdQuery,
  useGetDocumentKpisByIdsQuery,
  useGetExtractionsByDocumentIdQuery,
  useLazyGetAnnotationByOffsetQuery
} from '../../state/api';
import { TextAnnotation, TextExtraction } from '../../types/annotation';
import DocumentKpiList from './DocumentKpiList';
import DocumentPreview from './DocumentPreview';
import DocumentViewExtractionList from './DocumentViewExtractionList';

interface DocViewProps {
  children?: React.ReactNode;
  active: boolean;
  value: ViewType;
}

type ViewType = 'overview' | 'kpi';

function DocView(props: DocViewProps) {
  const { children, active, value, ...other } = props;
  return (
    <Box
      sx={{ overflowY: 'hidden', maxHeight: 'calc(100vh - 168px)' }}
      role="tabpanel"
      hidden={!active}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...other}
    >
      <Box sx={{ pt: 1, display: active ? 'block' : 'none' }}>{children}</Box>
    </Box>
  );
}

const MAX_HEIGHT = 'calc(100vh - 176px)';
const DocumentView = () => {
  const navigate = useNavigate();
  const { documentId } = useParams();
  const [activeView, setActiveView] = useState<ViewType>('overview');
  const [pagesToLoad, setPagesToLoad] = useState<number>(2);
  const [annotations, setAnnotations] = useState<TextAnnotation[]>([]);
  const [extractionIdLoading, setExtractionIdLoading] = useState<string | undefined>();
  const [currentAnnotation, setCurrentAnnotation] = useState<TextAnnotation | undefined>();
  const { data: document, isLoading: isDocumentLoading, error: documentError } = useGetDocumentByIdQuery(documentId);
  const { data: extractions, isLoading: isExtractionsLoading } = useGetExtractionsByDocumentIdQuery(documentId);
  const { data: kpis, isLoading: isKpisLoading } = useGetDocumentKpisByIdsQuery([documentId]);
  const [getAnnotationByOffset] = useLazyGetAnnotationByOffsetQuery();
  const [createCpmWorkflow] = useCreateCpmWorkflowMutation();

  const hasMorePages = useMemo(() => pagesToLoad < document?.pages.length, [pagesToLoad, document]);

  const handleCreateCpmWorkflow = (extractionIds: string[]) => {
    const req = {
      documentId: documentId,
      kpis: extractionIds
    };
    createCpmWorkflow(req)
      .unwrap()
      .then((mirrorId) => {
        navigate(`/mirrors/${mirrorId}`, { replace: true });
      });
  };
  const handleExtractionClick = async (extraction: TextExtraction) => {
    const existingAnnotation = annotations.find(
      (a) => a.startOffset === extraction.start && a.endOffset === extraction.end
    );
    if (!existingAnnotation) {
      setExtractionIdLoading(extraction.extractionId);
      const { data } = await getAnnotationByOffset({
        documentId: document.details.documentId,
        startOffset: extraction.start,
        endOffset: extraction.end
      });
      setExtractionIdLoading(undefined);

      if (pagesToLoad < data.page) {
        setPagesToLoad(data.page);
      }
      setCurrentAnnotation(data);
      setAnnotations([...annotations, data]);
    } else {
      setCurrentAnnotation({ ...existingAnnotation });
    }
  };
  const handleLoadMorePages = () => {
    setPagesToLoad(pagesToLoad + 2);
  };

  const breadcrumbItems: BreadcrumbItem[] = [{ label: 'Documents', to: '/documents' }];
  if (activeView === 'kpi') {
    breadcrumbItems.push({ label: document?.details.name ?? '', onClick: () => setActiveView('overview') });
    breadcrumbItems.push({ label: 'KPIs' });
  } else {
    breadcrumbItems.push({ label: document?.details.name ?? '' });
  }

  if (documentError) {
    return (
      <EmptyState title="Document not found" icon={<DescriptionTwoTone />}>
        Document with id {documentId} could not be found.{' '}
        <Link component={RouterLink} underline="hover" to="/documents">
          Go back to document list
        </Link>
      </EmptyState>
    );
  }

  return (
    <>
      <AppBreadcrumbs items={breadcrumbItems} />
      <SectionBody>
        <DocView active={activeView === 'overview'} value="overview">
          {document?.pages && extractions && (
            <Box display="grid" gridTemplateColumns="1fr 4fr">
              <DocumentViewExtractionList
                height={MAX_HEIGHT}
                extractions={extractions}
                loading={isExtractionsLoading}
                extractionIdLoading={extractionIdLoading}
                onViewChange={() => setActiveView('kpi')}
                onExtractionClick={handleExtractionClick}
              />
              <DocumentPreview
                documentId={document.details.documentId}
                pages={document.pages.slice(0, pagesToLoad)}
                hasMorePages={hasMorePages}
                totalPages={document.pages.length}
                loading={isDocumentLoading}
                height={MAX_HEIGHT}
                selectedAnnotation={currentAnnotation}
                onLoadMorePages={handleLoadMorePages}
              />
            </Box>
          )}
        </DocView>
        <DocView active={activeView === 'kpi'} value="kpi">
          <DocumentKpiList kpis={kpis} loading={isKpisLoading} onCreateCpmWorkflow={handleCreateCpmWorkflow} />
        </DocView>
      </SectionBody>
    </>
  );
};

export default DocumentView;
