import React from 'react';
import { Backdrop, CircularProgress, Paper, Table, TableContainer } from '@mui/material';

interface SectionTableProps {
  children?: React.JSX.Element | React.JSX.Element[];
  loading?: boolean;
  stickyHeader?: boolean;
  maxHeight?: string | number;
}

export default function SectionTable({ children, loading = false, stickyHeader, maxHeight }: SectionTableProps) {
  return (
    <TableContainer
      sx={{ position: 'relative', maxHeight, th: { backgroundColor: 'background.paper' } }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} stickyHeader={stickyHeader} size="small">
        {children}
      </Table>
      <Backdrop open={loading} sx={{ position: 'absolute', color: '#fff', opacity: '0.2 !important' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </TableContainer>
  );
}
