import Avatar from '@mui/material/Avatar';
import React from 'react';
import { User } from '../state/model';

interface UserAvatarProps {
  user: User;
  small?: boolean;
}

const UserAvatar = ({ user }: UserAvatarProps) => {
  return (
    <Avatar
      alt="User avatar"
      variant="circular"
      sx={{
        backgroundColor: 'primary.main',
        fontSize: '1.2rem',
        height: 32,
        width: 32
      }}
    >
      {user.username.charAt(0)}
    </Avatar>
  );
};

export default UserAvatar;
