import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import ProgressCircle from '../../components/ProgressCircle';
import { useAuth } from '../../hooks/useAuth';
import LandingPage from '../landingpage/LandingPage';
import SideBar from './Sidebar';
import TopBar from './TopBar';

export const ProtectedLayout = () => {
  const location = useLocation();
  const { user, isLoading, isSuccess, isError } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  if (isLoading && !isError) {
    return <ProgressCircle />;
  }

  if (isError) {
    return <div>Site is down</div>;
  }

  if (isSuccess && user) {
    if (!user.activated) {
      return <Navigate to="/activation" state={{ from: location.pathname }} replace />;
    }
  } else {
    return <LandingPage />;
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: 0,
        m: 0,
        display: 'grid',
        gridTemplateRows: '64px auto'
      }}
    >
      <Box component={'header'}>
        <TopBar open={sidebarOpen} onOpenClick={() => setSidebarOpen(true)} />
      </Box>

      <Box
        sx={{
          py: { xs: 0, sm: 2 },
          px: { xs: 0, sm: 2 },
          maxWidth: { xs: '100%', xl: '1600px' },
          minWidth: { xs: '100%', xl: '1600px' },
          margin: '0 auto'
        }}
      >
        <Box component={'aside'}>
          <SideBar open={sidebarOpen} onCloseClick={() => setSidebarOpen(false)} />
        </Box>

        <Box
          component={'main'}
          sx={{
            py: 1,
            px: 2,
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            overflow: 'hidden'
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
