import DeleteIcon from '@mui/icons-material/Delete';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Snackbar,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import dayjs from 'dayjs';
import localeEn from 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../components/ConfirmDialog';
import SectionTable from '../../components/SectionTable';
import SkeletonTableBody from '../../components/SkeletonTableBody';
import { useDeleteDocumentByIdMutation } from '../../state/api';
import { DocumentId } from '../../types/annotation';
import { DocumentDetails, SortOrder } from '../../types/document';
import { getShortenedFilename } from '../../utils/string.utils';
import { DocumentInProgress, DocumentNotCreated } from './Documents';

interface DocumentListProps {
  isLoading: boolean;
  documents: DocumentDetails[];
  documentsNotCreated: DocumentNotCreated[];
  documentsInProgressById: Record<DocumentId, DocumentInProgress>;
  sortedBy: string;
  sortOrder: SortOrder;
  onChangeSortOrder: () => void;
}

const DocumentNotInitiatedRows = ({
  documents
}: {
  documents: { id: string; name?: string; uploadedAt: string }[];
}) => {
  return documents.map(({ id, name, uploadedAt }) => (
    <React.Fragment key={id}>
      <TableRow>
        <TableCell>{uploadedAt}</TableCell>
        <TableCell colSpan={7}>{getShortenedFilename(name, 60)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell height={4} sx={{ padding: 0 }} colSpan={7}>
          <LinearProgress variant="indeterminate" />
        </TableCell>
      </TableRow>
    </React.Fragment>
  ));
};

const ContractingParties = ({ parties: contractingParties }: { parties: DocumentDetails['parties'] }) => {
  if (!contractingParties || contractingParties.length === 0) {
    return '-';
  }
  const parties = [...contractingParties].sort((a, b) => a.name.length - b.name.length);
  return parties
    .slice()
    .slice(0, 2)
    .map((party, idx) =>
      parties.length < 3 || idx === 0 ? (
        <Box component="p" key={party.extractionId}>
          {party.name}
        </Box>
      ) : (
        <Tooltip
          key={party.extractionId}
          title={parties.slice(1).map((party) => (
            <p key={party.extractionId}>{party.name}</p>
          ))}
        >
          <Chip
            sx={{ fontSize: '0.75rem', lineHeight: 1, height: 20 }}
            label={`+${parties.length - 1} more`}
            size="small"
          />
        </Tooltip>
      )
    );
};

const DocumentsOverview = ({
  documents,
  isLoading,
  documentsInProgressById,
  documentsNotCreated,
  sortedBy,
  sortOrder,
  onChangeSortOrder
}: DocumentListProps) => {
  const navigate = useNavigate();
  const [deleteDocument] = useDeleteDocumentByIdMutation();
  const [documentToDelete, setDocumentToDelete] = useState<DocumentDetails | undefined>(undefined);
  const [deleteMessage, setDeleteMessage] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const hasLocation = (text: DocumentDetails) => {
    return text.parties.some((t) => t.location && t.location.lat > 0);
  };

  const handleChangeSortOrder = () => {
    onChangeSortOrder();
  };

  const handleShowDeleteDialog = (event: React.MouseEvent, doc: DocumentDetails) => {
    event.stopPropagation();
    setOpenDeleteDialog(true);
    setDocumentToDelete(doc);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    // Animation needs to finish
    setTimeout(() => {
      setDocumentToDelete(undefined);
    }, 200);
  };

  const handleRemoveDeleteMessage = () => {
    setDeleteMessage('');
  };

  const handleDeleteDocument = async () => {
    try {
      await deleteDocument(documentToDelete.id).unwrap();
      setDeleteMessage(`Document deleted: ${documentToDelete.name}`);
      setOpenDeleteDialog(false);
      setTimeout(() => {
        setDocumentToDelete(undefined);
      }, 200);
    } catch (error) {
      console.error('Failed to delete document: ', error);
    }
  };

  const daysago = (postDate: Date | string) => {
    dayjs.extend(relativeTime).locale(localeEn);
    return dayjs(postDate).fromNow();
  };

  return (
    <>
      <SectionTable>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={sortOrder}>
              <TableSortLabel active={sortedBy === 'createdAt'} direction={sortOrder} onClick={handleChangeSortOrder}>
                Uploaded
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Name</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Parties</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Start Date</TableCell>
            <TableCell>
              <MapIcon />
            </TableCell>
            <TableCell>KPIs</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortOrder === 'desc' && (
            <DocumentNotInitiatedRows
              documents={documentsNotCreated.map((d) => ({ ...d, uploadedAt: daysago(d.uploadStarted) }))}
            ></DocumentNotInitiatedRows>
          )}
          {isLoading && <SkeletonTableBody rows={5} columns={7} />}
          {!isLoading &&
            documents &&
            documents.map((doc) => {
              return (
                <React.Fragment key={doc.id}>
                  <TableRow
                    key={doc.id}
                    sx={{ cursor: 'pointer' }}
                    hover
                    onClick={() => navigate(`/documents/${doc.id}`)}
                  >
                    <TableCell>{daysago(doc.updatedAt)}</TableCell>
                    <TableCell>{getShortenedFilename(doc.name, 50)}</TableCell>
                    <TableCell>
                      <ContractingParties parties={doc.parties} />
                    </TableCell>
                    <TableCell>{doc.startDate && new Date(doc.startDate).toISOString().substring(0, 10)}</TableCell>
                    <TableCell>
                      {hasLocation(doc) ? <LocationOnIcon color="primary" /> : <LocationOffIcon color="disabled" />}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{doc.kpis.length}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                        <IconButton aria-label="delete" onClick={(e) => handleShowDeleteDialog(e, doc)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {documentsInProgressById[doc.id]?.progress < 100 && (
                    <TableRow>
                      <TableCell sx={{ padding: 0 }} height={4} colSpan={7}>
                        <LinearProgress value={documentsInProgressById[doc.id].progress} variant="determinate" />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          {sortOrder === 'asc' && (
            <DocumentNotInitiatedRows
              documents={documentsNotCreated.map((d) => ({ ...d, uploadedAt: daysago(d.uploadStarted) }))}
            ></DocumentNotInitiatedRows>
          )}
        </TableBody>
      </SectionTable>

      <ConfirmDialog
        title="Delete document"
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteDocument}
      >
        Do you really want to delete &quot;{documentToDelete?.name}&quot;?
      </ConfirmDialog>

      <Snackbar
        open={deleteMessage.length > 0}
        autoHideDuration={3000}
        message={deleteMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleRemoveDeleteMessage}
      />
    </>
  );
};

export default DocumentsOverview;
