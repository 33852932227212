import { ThemeOptions } from '@mui/material';

export type ThemeMode = 'light' | 'dark';

export const getThemeOptions = (mode: 'light' | 'dark'): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light' && {
      primary: {
        main: '#528395'
      },
      secondary: {
        main: '#96897b'
      },
      background: {
        // default: '#f1f0f0', // bluish
        default: '#f8f8f8', // reddish
        // default: '#fdfdfd',
        paper: '#fdfdfd'
      },
      info: {
        main: '#0a7aa6'
      },
      success: {
        main: '#bcd0ad',
        light: '#679636'
      }
    }),
    ...(mode === 'dark' && {
      primary: {
        main: '#528395'
      },
      secondary: {
        main: '#96897b'
      },
      background: {
        default: '#1e1f22',
        // paper: '#1e1f22'
        paper: '#2b2d30'
      },
      info: {
        main: '#0a7aa6'
      },
      success: {
        main: '#bcd0ad',
        light: '#679636'
      }
    })
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'medium',
        sx: {
          borderRadius: 3
        }
      }
    },
    MuiTableCell: {
      defaultProps: {
        height: 56
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'medium'
      }
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      }
    }
  }
});
