import { KeyboardArrowRight } from '@mui/icons-material';
import {
  Badge,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { StandardLabel, TextExtraction } from '../../types/annotation';
import { findFirstTextExtractionByLabelName, normalisedExtractionValue } from '../../types/utils/extractions';

interface DocumentViewExtractionListProps {
  height: string;
  extractions: TextExtraction[];
  loading: boolean;
  extractionIdLoading?: string;
  onViewChange: () => void;
  onExtractionClick: (extraction: TextExtraction) => void;
}

function SkeletonList({ length }: { length: number }) {
  const items = Array.from({ length }, (_, i) => i);
  return (
    <List disablePadding>
      {items.map((item) => (
        <ListItem key={item} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Skeleton variant="text" width="100%" height={32} />
          <Skeleton variant="text" width="100%" />
        </ListItem>
      ))}
    </List>
  );
}

function ExtractionListItem({
  text,
  description,
  loading,
  selected,
  onClick
}: {
  text: string;
  description?: string;
  loading: boolean;
  selected: boolean;
  onClick: () => void;
}) {
  return (
    <ListItem disablePadding>
      <ListItemButton selected={selected} onClick={onClick}>
        <ListItemText
          primary={
            <p>
              {text}
              {loading && (
                <CircularProgress sx={{ position: 'absolute', right: 12, bottom: 'calc(50% - 10px)' }} size={20} />
              )}
            </p>
          }
          secondary={description}
          primaryTypographyProps={{
            sx: {
              display: '-webkit-box',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical'
            }
          }}
          secondaryTypographyProps={{
            sx: {
              display: '-webkit-box',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical'
            }
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default function DocumentViewExtractionList({
  height,
  extractions,
  loading,
  extractionIdLoading,
  onViewChange,
  onExtractionClick
}: DocumentViewExtractionListProps) {
  const [selectedExtraction, setSelectedExtraction] = useState<TextExtraction>();
  const kpiExtractions = useMemo(
    () => extractions.filter((extraction) => extraction.labelName === 'Kpi'),
    [extractions]
  );
  const otherExtractions = useMemo(
    () => extractions.filter((extraction) => extraction.labelName !== 'Kpi'),
    [extractions]
  );

  const textExtractionValue = (extraction: TextExtraction): string => {
    if (extraction.labelName == StandardLabel.ContractingParty) {
      return normalisedExtractionValue(findFirstTextExtractionByLabelName(extraction, 'PartyName'));
    } else {
      return normalisedExtractionValue(extraction);
    }
  };

  const handleExtractionClick = (extraction: TextExtraction) => {
    setSelectedExtraction(extraction);
    onExtractionClick(extraction);
  };

  if (loading) {
    return <SkeletonList length={10} />;
  }
  return (
    <List
      disablePadding
      sx={{
        maxHeight: height,
        overflowY: 'auto'
      }}
    >
      {kpiExtractions.length > 0 && (
        <>
          <ListItem disablePadding>
            <ListItemButton sx={{ paddingY: 2, paddingInlineEnd: 0 }} onClick={() => onViewChange()}>
              <Badge badgeContent={kpiExtractions.length} color="primary">
                <ListItemText>KPIs</ListItemText>
              </Badge>
              <ListItemSecondaryAction>
                <KeyboardArrowRight sx={{ color: 'text.secondary' }} />
              </ListItemSecondaryAction>
            </ListItemButton>
          </ListItem>
          <Divider />
        </>
      )}
      {otherExtractions.map((extraction) => (
        <ExtractionListItem
          key={extraction.extractionId}
          text={extraction.labelTitle}
          description={textExtractionValue(extraction)}
          loading={extractionIdLoading === extraction.extractionId}
          selected={selectedExtraction?.extractionId === extraction.extractionId}
          onClick={() => handleExtractionClick(extraction)}
        />
      ))}
    </List>
  );
}
