import { Box, Link } from '@mui/material';
import React from 'react';
import logoIcon from '../assets/digital-mirror-symbol-blue_192.png';
import { Link as RouterLink } from 'react-router-dom';
import Picture from './Picture';

interface LogoProps {
  showSymbol?: boolean;
  short?: boolean;
  showText?: boolean;
}

const Logo = ({ showSymbol = true, showText = true, short = false }: LogoProps) => {
  const logoText = () => {
    if (showText) {
      return short ? 'DM' : 'DIGITAL MIRROR';
    }

    return '';
  };

  return (
    <Link component={RouterLink} underline="none" variant="h6" to={'/'} noWrap>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        {showSymbol && (
          <Picture
            src={logoIcon}
            alt="Logo"
            sx={{
              width: '40px',
              height: '40px'
            }}
          />
        )}
        {logoText()}
      </Box>
    </Link>
  );
};

export default Logo;
