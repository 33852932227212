import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProgressCircle from '../../components/ProgressCircle';
import { TaskDefinition } from '../../types/task';
import {
  useGetMirrorByIdQuery,
  useGetMirrorStateByIdQuery,
  useGetWorkflowDefinitionByIdQuery,
  usePutMirrorNameMutation
} from '../../state/api';
import SectionHeader from '../../components/SectionHeader';
import { MirrorGraphPanel } from './MirrorGraphPanel';
import { MirrorTaskEditor } from './MirrorTaskEditor';
import { MirrorContextProvider } from './MirrorContextProvider';
import SectionBody from '../../components/SectionBody';
import { isMobile } from 'react-device-detect';
import { Button } from '@mui/material';

export const MirrorEditor = () => {
  const { mirrorId } = useParams();
  const [workflowId, setWorkflowId] = useState<string>('');
  const { data: mirror, isLoading: isMirrorLoading } = useGetMirrorByIdQuery(mirrorId, {
    skip: mirrorId === undefined
  });
  const { data: mirrorState } = useGetMirrorStateByIdQuery(mirrorId, {
    skip: mirrorId === undefined
  });
  const { data: workflow } = useGetWorkflowDefinitionByIdQuery(workflowId, {
    skip: workflowId.length === 0
  });
  const [selectedNode, setSelectedNode] = useState<TaskDefinition>(null);
  const [updateMirrorName, { isLoading: isMirrorNameSaving }] = usePutMirrorNameMutation();
  const [showGraph, setShowGraph] = useState<boolean>(!isMobile);

  useEffect(() => {
    if (mirror) {
      setWorkflowId(mirror.workflowId);
    }
  }, [mirror]);

  useEffect(() => {
    if (workflow && workflow.tasks && selectedNode) {
      const node = workflow.tasks.find((t) => t.taskId === selectedNode.taskId);
      setSelectedNode(node);
    }
  }, [workflow]);

  const handleSaveMirrorName = async (name: string) => {
    try {
      await updateMirrorName({
        mirrorId: mirrorId,
        name: name
      }).unwrap();
    } catch (error) {
      console.error('Failed to save label provider name', error);
    }
  };

  const handleTaskSelected = (task: TaskDefinition) => {
    setSelectedNode(task);
  };

  const handleTaskEditorClose = () => {
    setSelectedNode(undefined);
  };

  if (isMirrorLoading) {
    return <ProgressCircle />;
  }

  const showPanel = () => {
    if (selectedNode) {
      return <MirrorTaskEditor mirror={mirror} task={selectedNode} onClose={handleTaskEditorClose} />;
    }
    return (
      <>
        <SectionHeader title={mirror.name} onSaveName={handleSaveMirrorName} savingName={isMirrorNameSaving}>
          {isMobile && (
            <Button size="small" onClick={() => setShowGraph(!showGraph)}>
              {showGraph ? 'Hide' : 'Show'} Graph
            </Button>
          )}
        </SectionHeader>
        <SectionBody fillHeight>
          <MirrorGraphPanel
            mirror={mirror}
            mirrorState={mirrorState}
            workflow={workflow}
            onTaskSelected={handleTaskSelected}
            showGraph={showGraph}
          />
        </SectionBody>
      </>
    );
  };

  return (
    <MirrorContextProvider workflowId={workflowId}>
      {/*<MirrorLabelProviderPanel />*/}
      {showPanel()}
    </MirrorContextProvider>
  );
};
