import React from 'react';
import Box from '@mui/material/Box';
import ProgressCircle from './ProgressCircle';

interface MainHeaderProps {
  children: React.ReactNode;
  centered?: boolean;
  loading?: boolean;
  overflow?: string;
  fillHeight?: boolean;
}

export default function SectionBody({ children, centered = false, overflow, fillHeight, loading }: MainHeaderProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: fillHeight ? 'calc(100vh - 164px)' : 'auto',
        overflow,
        ...(centered && {
          alignItems: 'center'
        })
      }}
    >
      {loading ? <ProgressCircle /> : children}
    </Box>
  );
}
