import { AutoAwesomeTwoTone, CompareTwoTone } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Link, Snackbar, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../components/ConfirmDialog';
import CreateButton from '../../components/CreateButton';
import EmptyState from '../../components/EmptyState';
import SectionBody from '../../components/SectionBody';
import SectionHeader from '../../components/SectionHeader';
import SectionTable from '../../components/SectionTable';
import SkeletonTableBody from '../../components/SkeletonTableBody';
import { useDeleteWorkflowInstanceByIdMutation, useGetMirrorsQuery, useGetWorkflowsQuery } from '../../state/api';

export default function Workflows() {
  const navigate = useNavigate();

  const { data: mirrors = [], isLoading: isMirrorsLoading } = useGetMirrorsQuery();
  const { data: workflows = [], isLoading: isWorkflowsLoading } = useGetWorkflowsQuery();
  const [deleteWorkflow] = useDeleteWorkflowInstanceByIdMutation();
  const [deleteWorkflowId, setDeleteWorkflowId] = useState<string>('');
  const [deleteMessage, setDeleteMessage] = useState<string>('');

  const handleClickCreateFlow = () => {
    navigate('/workflows/create');
  };

  const handleDeleteShowDialog = (event, id: string) => {
    event.stopPropagation();
    setDeleteWorkflowId(id);
  };

  const handleDeleteCancel = () => {
    setDeleteWorkflowId('');
  };

  const handleRemoveDeleteMessage = () => {
    setDeleteMessage('');
  };

  const handleDeleteWorkflow = async () => {
    try {
      await deleteWorkflow(deleteWorkflowId).unwrap();
      setDeleteMessage(`Workflow deleted`);
      setDeleteWorkflowId('');
    } catch (error) {
      console.error('Failed to delete workflow: ', error);
    }
  };

  const renderBody = () => {
    const loading = isMirrorsLoading || isWorkflowsLoading;
    if (!loading && mirrors.length === 0) {
      return (
        <EmptyState title="No mirrors found" icon={<CompareTwoTone />}>
          You need to{' '}
          <Link to="/mirrors" component={RouterLink}>
            create a mirror
          </Link>{' '}
          before you can create workflows.
        </EmptyState>
      );
    }
    if (!loading && mirrors.length > 0 && workflows.length === 0) {
      return (
        <EmptyState title="No workflows found" icon={<AutoAwesomeTwoTone />}>
          No workflows created yet. Create a new workflow to get started.
        </EmptyState>
      );
    }
    return (
      <SectionTable>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <SkeletonTableBody rows={5} columns={3} />
          ) : (
            workflows.map((workflow) => (
              <TableRow
                key={workflow.workflowId}
                sx={{ cursor: 'pointer' }}
                hover
                onClick={() => navigate(`/workflows/${workflow.workflowInstanceId}`)}
              >
                <TableCell>{workflow.name}</TableCell>
                <TableCell>{workflow.status}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => handleDeleteShowDialog(e, workflow.workflowInstanceId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </SectionTable>
    );
  };

  return (
    <>
      <SectionHeader title="">
        <CreateButton title="Create workflow" onClick={handleClickCreateFlow} disabled={mirrors.length === 0} />
      </SectionHeader>

      <SectionBody>{renderBody()}</SectionBody>

      <ConfirmDialog
        title="Delete workflow"
        open={deleteWorkflowId.length > 0}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteWorkflow}
      >
        Do you really want to delete this workflow?
      </ConfirmDialog>

      <Snackbar
        open={deleteMessage.length > 0}
        autoHideDuration={6000}
        message={deleteMessage}
        onClose={handleRemoveDeleteMessage}
      />
    </>
  );
}
