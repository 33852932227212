interface Config {
  googleMapsApiKey: string;
}

interface SignupRequest {
  yearOfBirth: number;
  country: string;
}

interface UpdateUserRequest {
  yearOfBirth: number;
  country: string;
}

interface WebSocketDocumentEvent {
  documentId: string;
}

interface WebSocketChatEvent {
  chatId: string;
}

interface WebSocketCupEvent {
  cupId: string;
}

interface WebSocketTaskEvent {
  workflowInstanceId: string;
  taskId: string;
}

interface WebSocketIdEvent {
  id: string;
}

function isTaskEvent(
  s: WebSocketIdEvent | WebSocketTaskEvent | WebSocketDocumentEvent | WebSocketChatEvent | WebSocketCupEvent
): s is WebSocketTaskEvent {
  const e = s as WebSocketTaskEvent;
  return e.workflowInstanceId !== undefined && e.taskId !== undefined;
}

function isIdEvent(
  s: WebSocketIdEvent | WebSocketTaskEvent | WebSocketDocumentEvent | WebSocketChatEvent | WebSocketCupEvent
): s is WebSocketIdEvent {
  return (s as WebSocketIdEvent).id !== undefined;
}

function isDocumentEvent(
  s: WebSocketIdEvent | WebSocketTaskEvent | WebSocketDocumentEvent | WebSocketChatEvent | WebSocketCupEvent
): s is WebSocketDocumentEvent {
  return (s as WebSocketDocumentEvent).documentId !== undefined;
}

function isChatEvent(
  s: WebSocketIdEvent | WebSocketTaskEvent | WebSocketDocumentEvent | WebSocketChatEvent | WebSocketCupEvent
): s is WebSocketChatEvent {
  return (s as WebSocketChatEvent).chatId !== undefined;
}

interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  yearOfBirth: number;
  country: string;
  activated: boolean;
  admin: boolean;
}

interface ApiError {
  status: string;
  data?: unknown;
}

interface Address {
  addressId: string;
  regionCode: string;
  postalCode: string;
  administrativeArea: string;
  locality: string;
  sublocality: string;
  addressLines: string[];
}

interface ContractingParty {
  extractionId: string;
  name: string;
  address: Address;
}

interface ContractingPartyQuery {
  documentId: string;
  extractionId: string;
}

interface Location {
  lat: number;
  lng: number;
}

interface ContractingPartyBasic {
  name: string;
  extractionId: string;
  location?: Location;
}

interface Label {
  id: string;
  name: string;
  probability: number;
}

interface Entity {
  start: number;
  end: number;
  label: Label;
}

interface LabeledSentenceReference {
  id: string;
  sentenceId: string;
  start: number;
  end: number;
  label: Label[];
  entity: Entity[];
}

interface WhiteSpace {
  text: string;
}

interface LabeledSentence extends LabeledSentenceReference {
  text: string;
}

export {
  Address,
  ApiError,
  Config,
  ContractingParty,
  ContractingPartyBasic,
  ContractingPartyQuery,
  Entity,
  Label,
  LabeledSentence,
  LabeledSentenceReference,
  Location,
  SignupRequest,
  UpdateUserRequest,
  User,
  WebSocketDocumentEvent,
  WebSocketChatEvent,
  WebSocketCupEvent,
  WebSocketIdEvent,
  WebSocketTaskEvent,
  WhiteSpace,
  isDocumentEvent,
  isChatEvent,
  isIdEvent,
  isTaskEvent
};
