import { BarChart } from '@mui/icons-material';
import { Box, Checkbox, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import CreateButton from '../../components/CreateButton';
import EmptyState from '../../components/EmptyState';
import SectionTable from '../../components/SectionTable';
import SkeletonTableBody from '../../components/SkeletonTableBody';
import { DocumentKpi } from '../../types/document';

interface DocumentKpiListProps {
  kpis: DocumentKpi[];
  loading: boolean;
  onCreateCpmWorkflow: (extracitonIds: string[]) => void;
}

const DocumentKpiList = ({ kpis, loading, onCreateCpmWorkflow }: DocumentKpiListProps) => {
  const [selectedKpis, setSelectedKpis] = useState<string[]>([]);

  const isSelected = (kpi: DocumentKpi): boolean => {
    return selectedKpis.includes(kpi.extractionId);
  };

  const onSelectKpi = (kpi: DocumentKpi) => {
    if (isSelected(kpi)) {
      setSelectedKpis((cur) => cur.filter((k) => k !== kpi.extractionId));
    } else {
      setSelectedKpis((cur) => [...cur, kpi.extractionId]);
    }
  };

  const handleCreateWorkflow = async () => {
    onCreateCpmWorkflow(selectedKpis);
  };

  if (!loading && kpis.length === 0) {
    return (
      <EmptyState title="No KPIs found" icon={<BarChart />}>
        This document does not contain any KPIs.
      </EmptyState>
    );
  }

  return (
    <Box>
      <CreateButton
        sx={{ mb: 2 }}
        title="Create Workflow"
        onClick={handleCreateWorkflow}
        disabled={selectedKpis.length === 0}
      />
      <SectionTable stickyHeader maxHeight="calc(100vh - 228px)">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>KPI</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Target</TableCell>
            <TableCell>Frequency</TableCell>
            <TableCell>Responsible</TableCell>
            <TableCell>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <SkeletonTableBody rows={5} columns={7} />}
          {!loading &&
            kpis.map((kpi) => (
              <TableRow key={kpi.extractionId} hover>
                <TableCell>
                  <Checkbox size="small" checked={isSelected(kpi)} onChange={() => onSelectKpi(kpi)} />
                </TableCell>
                <TableCell>{kpi.name || '-'}</TableCell>
                <TableCell>{kpi.description || '-'}</TableCell>
                <TableCell>{kpi.target || '-'}</TableCell>
                <TableCell>{kpi.frequency.join(', ') || '-'}</TableCell>
                <TableCell>{kpi.responsible || '-'}</TableCell>
                <TableCell>{kpi.type || '-'}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </SectionTable>
    </Box>
  );
};

export default DocumentKpiList;
