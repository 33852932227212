interface ProcessDetails {
  documents: string[];
  processId: string;
  tenantId: string;
}

enum ProcessStatus {
  LOADING = 'LOADING',
  OK = 'OK'
}

export { ProcessDetails, ProcessStatus };
