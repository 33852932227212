import { Skeleton, TableCell, TableRow } from '@mui/material';
import React from 'react';

interface SkeletonTableBodyProps {
  rows: number;
  columns: number;
}

export default function SkeletonTableBody({ rows, columns }: SkeletonTableBodyProps) {
  const rowsArray = Array(rows).fill('');
  const columnsArray = Array(columns).fill('');

  return (
    <>
      {rowsArray.map((_, i) => (
        <TableRow key={i}>
          {columnsArray.map((_, j) => (
            <TableCell key={j} sx={{ height: '56px' }}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}
