import { DescriptionTwoTone } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmptyState from '../../components/EmptyState';
import { TextAnnotation, TextAnnotationType } from '../../types/annotation';
import { DocumentPage } from '../../types/document';
import DocumentPageSection from './DocumentPageSection';

interface DocumentProps {
  documentId: string;
  pages: DocumentPage[];
  height: string;
  loading: boolean;
  selectedAnnotation?: TextAnnotation;
  hasMorePages: boolean;
  totalPages: number;
  onLoadMorePages?: () => void;
}

const DocumentPreview = ({
  documentId,
  height,
  pages,
  totalPages,
  loading,
  hasMorePages,
  selectedAnnotation,
  onLoadMorePages
}: DocumentProps) => {
  const scrollRef = useRef<InfiniteScroll>(null);

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height={height} />;
  }
  if (pages.length === 0) {
    return (
      <EmptyState title="Document has no preview." icon={<DescriptionTwoTone />}>
        This document has probably not been processed yet.
      </EmptyState>
    );
  }

  useEffect(() => {
    if (selectedAnnotation && scrollRef) {
      const scrollElement = (scrollRef.current as unknown as { el: HTMLElement }).el;
      let pagesOffset = 0;
      for (let i = 0; i < selectedAnnotation.page; i++) {
        const page = i + 1;
        const { clientHeight } = scrollElement.children[i];
        if (page < selectedAnnotation.page) {
          // 16 is the gap between two pages
          pagesOffset += clientHeight + 16;
        } else if (page === selectedAnnotation.page) {
          const scaleY = clientHeight / pages.at(selectedAnnotation.page - 1).image.height;
          // Offset y + spacing
          pagesOffset += selectedAnnotation.box.y * scaleY - 48;
        }
      }
      scrollElement.scrollTo({
        top: pagesOffset,
        behavior: 'smooth'
      });
    }
  }, [scrollRef, selectedAnnotation]);

  return (
    <InfiniteScroll
      ref={scrollRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '1rem',
        overflowY: 'auto',
        padding: '0.75rem'
      }}
      height={height}
      dataLength={pages.length}
      next={onLoadMorePages}
      hasMore={hasMorePages}
      loader={null}
    >
      {pages.map((page) => (
        <DocumentPageSection
          key={page.page}
          documentId={documentId}
          page={page}
          selectedAnnotation={selectedAnnotation}
          showAnnotations={[TextAnnotationType.EXTRACTION]}
          totalPages={totalPages}
        />
      ))}
    </InfiniteScroll>
  );
};

export default DocumentPreview;
