import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { NavigateNext } from '@mui/icons-material';

export interface BreadcrumbItem {
  label: string;
  to?: string;
  onClick?: () => void;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
}

export default function AppBreadcrumbs({ items }: BreadcrumbsProps) {
  return (
    <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
      {items.map((item, idx) =>
        item.to ? (
          <Link key={idx} component={RouterLink} to={item.to} underline="hover" color="inherit">
            {item.label}
          </Link>
        ) : item.onClick ? (
          <Link key={idx} sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={item.onClick}>
            {item.label}
          </Link>
        ) : (
          <Typography key={idx} color="text.primary">
            {item.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
}
