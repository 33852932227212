import { Theme } from '@emotion/react';
import { Box, SvgIcon, SxProps, Typography } from '@mui/material';
import React from 'react';

interface EmptyStateProps {
  sx?: SxProps<Theme>;
  title: string;
  children?: React.ReactNode;
  icon?: React.ReactElement;
}

export default function EmptyState({ title, children, icon, sx }: EmptyStateProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        mt: 8,
        maxWidth: '350px',
        mx: 'auto',
        ...sx
      }}
    >
      <SvgIcon color="disabled" sx={{ fontSize: '10rem' }}>
        {icon}
      </SvgIcon>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
}
