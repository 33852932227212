import { Tab, TabOwnProps, Tabs } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface NavigationTabProps extends TabOwnProps {
  to: string;
}

const NavigationTab = ({ value, label, to, ...rest }: NavigationTabProps) => (
  <Tab
    {...rest}
    sx={{
      fontSize: '1rem',
      '&.Mui-selected': { color: 'action.active' },
      ':hover': { color: 'action.active' }
    }}
    value={value}
    label={label}
    component={Link}
    to={to}
  />
);

export default function NavigationTabs() {
  const navigationItems = [
    {
      label: 'Documents',
      to: '/documents'
    },
    {
      label: 'Workflows',
      to: '/workflows'
    },
    {
      label: 'Mirrors',
      to: '/mirrors'
    }
  ];

  const selectedTab = navigationItems.find((item) => window.location.pathname.startsWith(item.to));
  return (
    <Tabs sx={{ display: { xs: 'none', sm: 'flex' } }} value={selectedTab?.label ?? false}>
      {navigationItems.map((item) => (
        <NavigationTab key={item.to} value={item.label} label={item.label} to={item.to} />
      ))}
    </Tabs>
  );
}
