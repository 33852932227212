import { BarChart } from '@mui/icons-material';
import { Box, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import EmptyState from '../../components/EmptyState';
import SectionTable from '../../components/SectionTable';
import SkeletonTableBody from '../../components/SkeletonTableBody';
import StatusDot from '../../components/StatusDot';
import { getShortenedFilename } from '../../utils/string.utils';
import { DocumentDetails } from '../../types/document';

interface DocumentKpiViewProps {
  documents: DocumentDetails[];
  isLoading: boolean;
}

const LabelValue = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        justifyItems: 'start',
        gridTemplateColumns: '2.5rem auto',
        columnGap: 0.5
      }}
    >
      <span className="font-bold">{label}</span>
      <span>{value}</span>
    </Box>
  );
};

const DocumentsKpiView = ({ documents = [], isLoading }: DocumentKpiViewProps) => {
  const documentWithKpis = documents.filter((d) => d.kpis.length > 0);
  if (!isLoading && documentWithKpis?.length === 0) {
    return (
      <EmptyState title="No KPIs found" icon={<BarChart />}>
        No documents with KPIs found.
      </EmptyState>
    );
  }
  return (
    <SectionTable>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>KPIs</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <SkeletonTableBody rows={5} columns={2} />
        ) : (
          documentWithKpis.map((document) => (
            <TableRow key={document.id}>
              <TableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="body1" sx={{ ':hover': { textDecoration: 'underline' } }}>
                    <Link to={`/documents/${document.id}`}>{getShortenedFilename(document.name, 50)}</Link>
                  </Typography>
                  <Typography variant="caption">{document.parties.map((p) => p.name).join(' | ')}</Typography>
                </Box>
              </TableCell>
              <TableCell>
                {document.kpis.map((kpi, idx, arr) => (
                  <React.Fragment key={kpi.extractionId}>
                    <StatusDot
                      status={kpi.status}
                      tooltip={
                        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5, p: 1 }}>
                          <div>
                            <Typography variant="body2">{kpi.name}</Typography>
                          </div>
                          {kpi.target && <LabelValue label="Target" value={kpi.target} />}
                          {<LabelValue label="Actual" value={kpi.actual || '-'} />}
                        </Box>
                      }
                    />
                    {arr[idx + 1] && kpi.status !== arr[idx + 1].status && <br />}
                  </React.Fragment>
                ))}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </SectionTable>
  );
};

export default DocumentsKpiView;
