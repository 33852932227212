import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';
import { MapPoint } from '../../../components/MapView';
import { useGetContractingPartyQuery } from '../../../state/api';
import { ColorModeProvider } from '../../../theme-context';
import { ContractingPartyMarker } from './model';

interface ContractingPartyCardProps {
  point: MapPoint<ContractingPartyMarker>;
}

export const ContractingPartyCard = ({ point }: ContractingPartyCardProps) => {
  const { data, isLoading } = useGetContractingPartyQuery({
    documentId: point.data.documentId,
    extractionId: point.data.extractionId
  });

  return (
    <ColorModeProvider mode="light">
      <Card sx={{ padding: 0, margin: 0 }} elevation={0}>
        {!isLoading && (
          <CardContent sx={{ padding: 0, margin: 0 }}>
            <Typography variant="subtitle2" component="div">
              {data.name}
            </Typography>
            <Divider sx={{ pb: 0 }} />
            <Typography sx={{ pt: 1 }} variant="body2" color="text.secondary">
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {data.address.addressLines[0] ? <span>{data.address.addressLines[0]}</span> : null}
                {data.address.addressLines[1] ? <span>{data.address.addressLines[1]}</span> : null}
                {data.address.postalCode ? (
                  <span>
                    {data.address.locality} {data.address.postalCode}
                  </span>
                ) : null}
                {data.address.administrativeArea ? <span>{data.address.administrativeArea}</span> : null}
                {data.address.regionCode ? <span>{data.address.regionCode}</span> : null}
              </Box>
            </Typography>
          </CardContent>
        )}
      </Card>
    </ColorModeProvider>
  );
};
