import { Badge, Box, InputBase, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SendOutlined from '@mui/icons-material/SendOutlined';
import React, { useRef, useState } from 'react';
import { AttachFile } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { chatCreateTextThunk, chatUploadFilesThunk } from '../../state/chatSlice';
import { store } from '../../state/store';

interface ChatConsoleProps {
  disabled: boolean;
  chatId: string;
  uploadEnabled?: boolean;
}

export type AppDispatch = typeof store.dispatch;

const ChatConsole = ({ disabled, chatId, uploadEnabled }: ChatConsoleProps) => {
  const [message, setMessage] = useState<string>('');
  const [files, setFiles] = useState<FileList | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const fileInputRef = useRef(null);

  function handleTyping(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    setMessage(newValue);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && message.length > 0) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (files && files.length) {
      dispatch(
        chatUploadFilesThunk({
          chatId: chatId,
          message: message,
          files: files
        })
      );
    } else if (message.length > 0) {
      dispatch(
        chatCreateTextThunk({
          chatId: chatId,
          text: message
        })
      );
    }

    setFiles(null);
    setMessage('');
    fileInputRef.current.value = '';
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length) {
      setFiles(selectedFiles);
    }
  };

  const handleAttachClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        p: 1
      }}
    >
      <Paper
        elevation={0}
        sx={(theme) => ({
          p: '0.2rem 0.4rem',
          display: 'flex',
          width: '100%',
          maxWidth: '48rem',
          borderRadius: 3,
          outline: '1px solid',
          outlineColor: theme.palette.divider,
          '&:hover': {
            outlineColor: theme.palette.text.primary
          },
          '&:focus-within': {
            outline: '2px solid',
            outlineColor: theme.palette.primary.main
          }
        })}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }} // Hide the file input
          onChange={handleFileChange}
          multiple
        />
        {uploadEnabled && (
          <Badge badgeContent={files ? files.length : 0} invisible={!files || files.length === 0} color="primary">
            <IconButton type="button" onClick={handleAttachClick} aria-label="attach file">
              <AttachFile fontSize="small" />
            </IconButton>
          </Badge>
        )}
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Send a message..."
          inputProps={{ 'aria-label': 'send a message' }}
          disabled={disabled}
          value={message}
          multiline
          onChange={handleTyping}
          onKeyDown={handleKeyDown}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSendMessage}>
          <SendOutlined
            fontSize="small"
            sx={{
              color: !disabled && message.length > 0 ? 'action.active' : 'action.disabled'
            }}
          />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default ChatConsole;
