import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { MenuList } from './MenuList';

interface SideBarProps {
  open: boolean;
  onCloseClick: () => void;
}

const CloseButton = ({ onClick }) => {
  return (
    <IconButton
      aria-label="Open drawer"
      onClick={onClick}
      edge="start"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        mr: 1,
        mt: 1
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

const SideBar = ({ open, onCloseClick }: SideBarProps) => {
  const children = <MenuList onClick={onCloseClick} />;

  return (
    <Box
      sx={{
        maxWidth: { sm: '320px' }
      }}
    >
      <Drawer
        variant="temporary"
        open={open}
        onClose={onCloseClick}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          width: '100%',
          bgcolor: 'background.default',
          '& .MuiDrawer-paper': {
            width: '100%',
            backgroundImage: 'none'
          }
        }}
      >
        <Box
          sx={{
            top: 0,
            right: 0,
            m: 2,
            mb: 5
          }}
        >
          <CloseButton onClick={onCloseClick} />
        </Box>
        {children}
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none' },
          bgcolor: 'background.default',
          '& .MuiDrawer-paper': {
            bgcolor: 'background.default',
            position: 'fixed',
            overflow: 'clip',
            top: '74px',
            maxHeight: 'calc(100vh - 74px)',
            minWidth: '220px',
            border: 'none'
          }
        }}
      >
        {children}
      </Drawer>
    </Box>
  );
};

export default SideBar;
