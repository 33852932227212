import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import Logo from '../../components/Logo';
import ProfileMenu from '../../components/ProfileMenu';
import NavigationTabs from './NavigationTabs';

interface TopBarProps {
  open: boolean;
  onOpenClick: () => void;
}

const TopBar = ({ onOpenClick }: TopBarProps) => {
  return (
    <Box
      sx={(theme) => ({
        px: 4,
        py: 1,
        position: 'fixed',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
        zIndex: 1,
        backdropFilter: 'blur(8px)',
        backgroundColor: 'rgba(248 248 248 / 0.6)',
        ...theme.applyStyles('dark', {
          backgroundColor: 'rgba(30 31 34 / 0.6)'
        })
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1
        }}
      >
        <IconButton sx={{ display: { sm: 'none' } }} color="inherit" onClick={onOpenClick} edge="start">
          <MenuIcon />
        </IconButton>
        <Logo showText={true} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <NavigationTabs />
        <ProfileMenu />
      </Box>
    </Box>
  );
};

export default TopBar;
