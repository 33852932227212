import { Button } from '@mui/material';
import React from 'react';
import { OverridableStringUnion } from '@mui/types';
import { ButtonProps, ButtonPropsSizeOverrides } from '@mui/material/Button/Button';

interface CreateButtonProps extends ButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  size?: OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>;
}

const CreateButton = ({ title, onClick, disabled = false, size = 'medium', ...props }: CreateButtonProps) => {
  return (
    <Button
      {...props}
      sx={{ ...props.sx, borderRadius: '3rem' }}
      variant="contained"
      color="primary"
      onClick={onClick}
      size={size}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export default CreateButton;
