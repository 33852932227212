import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface DocumentProgressState {
  progressing: { id: string; progress: number }[];
  notCreated: { id: string; name: string; uploadStarted: string }[];
}

const initialState: DocumentProgressState = {
  progressing: [],
  notCreated: []
};

const documentProgressSlice = createSlice({
  name: 'documentProgress',
  initialState,
  reducers: {
    initDocumentUpload: (state, { payload }: PayloadAction<{ id: string; name: string }[]>) => {
      state.notCreated = state.notCreated.concat(
        payload.map(({ id, name }) => ({
          id,
          name,
          uploadStarted: new Date().toISOString()
        }))
      );
    },
    updateDocumentUpload: (state, { payload }: PayloadAction<{ id: string; progress: number }>) => {
      const document = state.progressing.find((d) => d.id === payload.id);
      if (document) {
        document.progress = payload.progress;
      } else {
        state.progressing = state.progressing.concat({ id: payload.id, progress: payload.progress });
      }
    },
    finishDocumentUpload: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.progressing = state.progressing.filter((d) => d.id !== payload.id);
      state.notCreated = state.notCreated.filter((d) => d.id !== payload.id);
    }
  }
});

export const { initDocumentUpload, updateDocumentUpload, finishDocumentUpload } = documentProgressSlice.actions;

export const selectDocumentsInProgress = (state: RootState) => state.documentProgress.progressing;
export const selectDocumentsInProgresNotCreated = (state: RootState) => state.documentProgress.notCreated;
export const selectDocumentsInProgressById = createSelector([selectDocumentsInProgress], (documents) =>
  documents.reduce((acc, d) => ({ ...acc, [d.id]: d }), {})
);

export default documentProgressSlice.reducer;
