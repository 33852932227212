import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLazyGetDocumentPageAnnotationsQuery, useLazyGetDocumentPageLinesQuery } from '../../state/api';
import { DocumentPageRequest, TextAnnotation, TextAnnotationType } from '../../types/annotation';
import { DocumentPage } from '../../types/document';
import DocumentOverlay from './DocumentOverlay';

interface DocumentPageProps {
  documentId: string;
  page: DocumentPage;
  showAnnotations: TextAnnotationType[];
  selectedAnnotation?: TextAnnotation;
  totalPages: number;
}

const DocumentPageSection = ({
  documentId,
  page,
  showAnnotations,
  selectedAnnotation,
  totalPages
}: DocumentPageProps) => {
  const pageRequest: DocumentPageRequest = {
    documentId,
    page: page.page,
    types: showAnnotations
  };

  const [getLines, { data: lines = [] }] = useLazyGetDocumentPageLinesQuery();
  const [getAnnotations, { data: annotations = [] }] = useLazyGetDocumentPageAnnotationsQuery();
  const [loadImage, setLoadImage] = useState(!selectedAnnotation || selectedAnnotation?.page === page.page);

  useEffect(() => {
    const fetchData = () => {
      if (showAnnotations.length > 0) {
        getAnnotations(pageRequest);
      }
      if (showAnnotations.includes(TextAnnotationType.LINE)) {
        getLines(pageRequest);
      }
    };
    // Prioritize selected annotation jump to
    if (selectedAnnotation && selectedAnnotation?.page === page.page) {
      fetchData();
    } else {
      setTimeout(() => {
        fetchData();
        setLoadImage(true);
      }, 0);
    }
  }, []);

  const imageUri = `/api/documents/${documentId}/page/${page.page}/image`;
  const viewBox = `0 0 ${page.image.width} ${page.image.height}`;

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        position: 'relative',
        boxShadow: theme.shadows[1]
      })}
    >
      <Box
        component="svg"
        sx={{
          fill: 'none',
          display: 'block',
          maxWidth: '100%',
          backgroundColor: '#fff',
          gridArea: '1 / 1'
        }}
        viewBox={viewBox}
      >
        {loadImage && <Box component="image" width="100%" href={imageUri} />}
      </Box>

      <DocumentOverlay
        width={page.image.width}
        height={page.image.height}
        lines={lines}
        annotations={annotations}
        selectedAnnotation={selectedAnnotation}
        renderText={false}
        renderCursor={false}
      />
      <Typography
        variant="caption"
        color="black"
        sx={{ position: 'absolute', bottom: '1.5rem', right: '2rem' }}
      >{`${page.page} of ${totalPages}`}</Typography>
    </Box>
  );
};

export default DocumentPageSection;
